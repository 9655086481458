import { ModalContext, useAddNotification } from "@/shared/lib/react";
import React, { useContext, useEffect, useState } from "react";
import { Input, IInput } from "@yamaha-admin-sb/input";
import {
  ModalBody,
  ModalFooter,
  Modal,
  ModalTop,
  Loader,
  CustomNotification,
  Controller,
} from "@/shared/ui";
import { Button } from "@yamaha-admin-sb/button";
import { Dropdown, IDropdown, IDropdownItem } from "@yamaha-admin-sb/dropdown";
import {
  ContactTypeEnum,
  GetClientsPagination,
  GetClientsSortInfo,
  useAttachContactToClientMutation,
  useCreateClientMutation,
  useCreateContactMutation,
  useGetClientsQuery,
  useGetContactByValueQuery,
  useGetLServicesQuery,
} from "@/shared/lib/graphql";
import { LeadServiceSlugToText, PAGINATION_DEFAULT_OPTIONS } from "@/shared/helpers/const";
import { useForm } from "react-hook-form";
import {
  requiredValidationRule,
  inputValidationRule,
  phoneValidationRule,
} from "@/shared/helpers/const";
import { useGetMe } from "@/entities/session";
import styled from "styled-components";
import { colors } from "@/shared/helpers";
import { ITab, Tabs } from "@yamaha-admin-sb/tabs";

export interface IModal {
  phone?: string;
  isLoading?: boolean;
  onSuccess: (data: ICreateLeadFrom) => void;
  onCancel: () => void;
}

export interface ICreateLeadFrom {
  name: string;
  surname: string;
  phone: string;
  lServiceId: number;
  managerId?: number;
  clientId?: number;
}

const ExistClient = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: ${colors.gray200};
  row-gap: 10px;
  @media (min-width: 1024px) {
    padding: 16px;
  }
`;

const ExistClientTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
`;

const ExistClientInfo = styled.div`
  font-size: 14px;
`;

const contactTabs: Omit<ITab, "onClick" | "onAddClick">[] = [
  {
    title: "Новый клиент",
    value: "create",
  },
  {
    title: "Добавить к существующему",
    value: "attach",
  },
];

const AutoCompleteRoles = ["SN_MANAGER", "MANAGER"];

export const ModalCreateRequest: React.FC<IModal> = (props): JSX.Element => {
  const { isLoading, phone, onSuccess, onCancel } = props;
  const [selectedClient, setSelectedClient] = useState(null);
  const [contactActionTab, setContactActionTab] = useState<Omit<ITab, "onClick" | "onAddClick">>(
    contactTabs[0]
  );
  const notify = useAddNotification();
  const { me } = useGetMe();
  const [_, setModalContext = () => ({})] = useContext(ModalContext);
  const { data: leadServices } = useGetLServicesQuery();
  const enableFetchContactData = !!phone;

  const {
    data: existContact,
    refetch,
    isLoading: contactIsLoading,
  } = useGetContactByValueQuery(
    {
      input: phone as string,
    },
    {
      enabled: enableFetchContactData,
    }
  );

  const [form, setForm] = useState<ICreateLeadFrom>({
    name: existContact?.getContactByValue?.client?.name
      ? existContact?.getContactByValue.client.name
      : "",
    surname: existContact?.getContactByValue?.client?.surname
      ? existContact?.getContactByValue.client.surname
      : "",
    phone: phone || "",
    lServiceId: 0,
    clientId: existContact?.getContactByValue?.client?.id,
  });

  const { mutate: createClient, isLoading: isLeadCreating } = useCreateClientMutation();

  const handleCreateClient = () => {
    createClient(
      {
        input: {
          contact: {
            name: form.name,
            surname: form.surname,
            value: form.phone,
            type: ContactTypeEnum.Phone,
          },
          name: form.name,
          surname: form.surname,
        },
      },
      {
        onSuccess: (resp) => {
          //handleCloseModal("modalCreateRequest");
          refetch();
          notify(<CustomNotification title="Новый клиент добавлен" />, "default");
        },
      }
    );
  };

  const { mutate: createContact, isLoading: isContactCreating } = useCreateContactMutation();

  const handleCreateContact = () => {
    if (!selectedClient) {
      notify(<CustomNotification title="Клиент не выбран" />, "default");
      return;
    }
    createContact(
      {
        input: {
          name: form.name,
          surname: form.surname,
          value: form.phone,
          type: ContactTypeEnum.Phone,
        },
      },
      {
        onSuccess: (resp) => {
          attachContact(
            {
              contactId: resp.createContact.id,
              input: {
                clientId: selectedClient,
              },
            },
            {
              onSuccess: (resp) => {
                //handleCloseModal("modalCreateRequest");
                refetch();
                notify(<CustomNotification title="Контакт добавлен для клиента" />, "default");
              },
            }
          );
        },
      }
    );
  };

  const { mutate: attachContact, isLoading: isAttaching } = useAttachContactToClientMutation();

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({ mode: "onBlur" });

  const handleChange = (key: string, value: string | number) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };
  useEffect(() => {
    refetch();
    return () => { };
  }, []);

  const handleCreate = () => {
    if (contactActionTab.value === "attach") {
      handleCreateContact();
    }
    else {
      handleCreateClient();
    }
    onSuccess(
      AutoCompleteRoles.includes(me?.getMe.role?.slug || "")
        ? { ...form, managerId: me?.getMe.id }
        : form
    );
  };

  const handleButtonUndoClick = () => {
    setModalContext((prevState) => ({
      ...prevState,
      modalCreateRequest: false,
    }));
  };

  const [searchByClientsQuery, setSearchByClientsQuery] = useState<string>("");
  const [sort, setSort] = useState<GetClientsSortInfo>({});
  const [pagination, setPagination] = useState<GetClientsPagination>(PAGINATION_DEFAULT_OPTIONS);
  const {
    data: clients,
    refetch: updateClients,
    isLoading: clientsLoading,
  } = useGetClientsQuery({
    input: {
      pagination,
      sort,
      filter: {
        query: searchByClientsQuery,
      },
    },
  });

  const preparedClientsData: IDropdownItem[] =
    clients?.getClients?.data?.map((item) => ({
      name: `${item.name} ${item.surname}`,
      value: item.id,
    })) ?? [];

  const options = [
    ...(leadServices?.getLServices?.map((el) => ({
      id: el.id,
      name: LeadServiceSlugToText[el.slug],
    })) || []),
  ];

  useEffect(() => {
    phone && setValue("phone", phone);
    existContact?.getContactByValue?.client?.id &&
      setValue("clientId", existContact?.getContactByValue?.client?.id);
  }, [phone, existContact?.getContactByValue?.client?.id]);

  return (
    <Modal name="modalCreateRequest">
      <ModalTop title="Создание заявки" onClose={handleButtonUndoClick} />
      <form onSubmit={handleSubmit(handleCreate)}>
        <ModalBody>
          <div className="space-20">
            <Controller<IDropdown>
              name="lServiceId"
              label="Тип заявки *"
              placeholder="Выберите тип"
              isBordered={true}
              control={control}
              rules={requiredValidationRule}
              error={errors?.lServiceId?.message}
              options={options || []}
              value={[options?.find((item) => item.id === form.lServiceId) || []].flat() || []}
              onChange={(value: IDropdownItem) => {
                value[0].id && handleChange("lServiceId", value[0].id);
                console.log("value[0].id", value[0].id);
              }}
              component={(dropdownProps: IDropdown) => <Dropdown {...dropdownProps} />}
            />
          </div>
          {contactIsLoading ? (
            <Loader size="l" />
          ) : existContact ? (
            <ExistClient className="row col space-20">
              <ExistClientTitle className="">Клиент</ExistClientTitle>
              <ExistClientInfo>
                <div>
                  {existContact.getContactByValue?.client?.name}{" "}
                  {existContact.getContactByValue?.client?.surname}
                </div>
                <span>{existContact.getContactByValue.value}</span>
              </ExistClientInfo>
            </ExistClient>
          ) : (
            <>
              <div className="flex">Клиент с номером {form.phone} не найден в базе</div>
              <div className="space-20">
                <Tabs
                  tabs={contactTabs}
                  activeTab={contactActionTab}
                  onClick={(value) => setContactActionTab(value)}
                  onAddClick={(value) => console.log("onAddClick", value)}
                />
              </div>
              {contactActionTab.value === "create" && (
                <>
                  <div className="space-20">
                    <Controller<IInput>
                      name="name"
                      label="Имя клиента *"
                      placeholder="Введите имя"
                      control={control}
                      error={errors?.name?.message}
                      value={form.name}
                      isBordered={true}
                      rules={inputValidationRule}
                      onChange={(value) => handleChange("name", value)}
                      component={(inputProps: IInput) => <Input {...inputProps} />}
                    />
                  </div>
                  <div className="space-20">
                    <Controller<IInput>
                      isBordered={true}
                      name="surname"
                      label="Фамилия клиента"
                      placeholder="Введите фамилию"
                      onChange={(value) => handleChange("surname", value)}
                      control={control}
                      error={errors?.surname?.message}
                      value={form.surname}
                      component={(inputProps: IInput) => <Input {...inputProps} />}
                    />
                  </div>
                  <div className="space-20">
                    <Controller<IInput>
                      mask="+7 (999) 999-99-99"
                      name="phone"
                      label="Номер телефона *"
                      placeholder="Введите номер телефона"
                      isBordered={true}
                      onChange={(value) => handleChange("phone", value)}
                      control={control}
                      error={errors?.phone?.message}
                      value={form.phone}
                      rules={phoneValidationRule}
                      component={(inputProps: IInput) => <Input {...inputProps} />}
                    />
                  </div>
                  {/* <Button isLoading={isLoading} onClick={() => handleCreateClient()} type="button">
                    Создать клиента
                  </Button> */}
                </>
              )}
              {contactActionTab.value === "attach" && (
                <>
                  <div className="space-20">
                    <Controller<IDropdown>
                      name="existClients"
                      label="Клиенты"
                      searchable={true}
                      placeholder="Поиск по ФИО"
                      isBordered={true}
                      control={control}
                      rules={requiredValidationRule}
                      options={preparedClientsData}
                      onChange={(value: IDropdownItem) => {
                        setSelectedClient(value[0].value);
                        console.log("value", value);
                      }}
                      onSearch={(value) => setSearchByClientsQuery(value)}
                      component={(dropdownProps: IDropdown) => <Dropdown {...dropdownProps} />}
                    />
                  </div>
                  {/*  <Button
                    isLoading={isLoading}
                    isDisabled={!!!selectedClient}
                    onClick={() => handleCreateContact()}
                    type="button"
                  >
                    Добавить
                  </Button> */}
                </>
              )}
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <Button variant="secondary" color="gray" onClick={onCancel}>
            Отменить
          </Button>
          <Button isLoading={isLoading} isDisabled={
            !!!selectedClient && contactActionTab.value === "attach"
          } type="submit">
            Создать
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
