import { ModalContext } from "@/shared/lib/react";
import { FC, useContext, useState } from "react";
import styled from "styled-components";
import { Calendar } from "./Calendar/Calendar";
import { Button } from "@yamaha-admin-sb/button";
import { colors } from "@/shared/helpers";
import { Icon } from "@yamaha-admin-sb/icon";
import moment from "moment";
import { ReminderRow, ModalReminderInfo } from "@/entities/reminder";
import { ModalCreateReminder } from "./ModalCreateReminder";
import { ModalEditReminder } from "./ModalEditReminder";
import { ReminderEntity, RoleEnum, useGetRemindersQuery } from "@/shared/lib/graphql";
import { useGetMe } from "@/entities/session";
import { ModalDeleteReminder } from "./ModalDeleteReminder";

function checkDate(date: Date | null) {
  if (!date) return "";

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const tomorrow = new Date(today);
  const yesterday = new Date(today);
  const providedDate = new Date(date);

  tomorrow.setDate(today.getDate() + 1);
  yesterday.setDate(today.getDate() - 1);

  providedDate.setHours(0, 0, 0, 0);

  if (providedDate.getTime() === today.getTime()) return "Сегодня";
  if (providedDate.getTime() === tomorrow.getTime()) return "Завтра";
  if (providedDate.getTime() === yesterday.getTime()) return "Вчера";
  return "";
}

const StyledContainer = styled.div`
  padding: 24px;
  display: flex;
  align-items: start;
  gap: 12px;
  height: 100%;
`;

const StyledDivider = styled.div`
  width: 16px;
  height: 100%;
  min-height: 300px;
  background-color: ${colors.gray300};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 8px;
  width: 100%;
`;

const StyledTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: ${colors.blue400};
  padding: 8px;
`;

const StyledTitle = styled.div`
  font-size: 13px;
`;

const StyledDayLabel = styled.span`
  font-weight: 700;
`;

interface IReminderProps {
  leadId?: number;
  userId?: number;
  isModal?: boolean;
}

export const Reminder: FC<IReminderProps> = ({ leadId, userId, isModal }) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);
  const [showCalendar, setShowCalendar] = useState(true);
  const [selectedReminder, setSelectedReminder] = useState<ReminderEntity | null>(null);

  const { me } = useGetMe();

  const myRole = me?.getMe.role?.slug;

  const hasPermissionForAllReminders =
    myRole === RoleEnum.Admin || myRole === RoleEnum.Director || myRole === RoleEnum.HeadOfMn;

  const { data, refetch } = useGetRemindersQuery(
    {
      input: {
        filter: {
          userId: hasPermissionForAllReminders ? undefined : (me?.getMe.id as number),
          remindDate: moment(selectedDate).format("YYYY-MM-DD"),
        },
      },
    },
    {
      enabled: !!me?.getMe.id,
    }
  );

  const formattedDateString = selectedDate ? moment(selectedDate).format("DD.MM.YY") : "";

  const dayLabel = checkDate(selectedDate);

  const handleOpenReminder = (reminder) => {
    setSelectedReminder(reminder);
    setModalContext((prev) => ({ ...prev, modalReminderInfo: true }));
  };

  const handleOpenCreateModal = () => {
    setModalContext((prev) => ({ ...prev, modalCreateReminder: true }));
  };

  const handleEditReminder = () => {
    setModalContext((prev) => ({ ...prev, modalReminderInfo: false, modalEditReminder: true }));
  };

  return (
    <>
      <StyledContainer>
        {showCalendar && <Calendar selected={selectedDate} onChange={setSelectedDate} />}
        <StyledDivider onClick={() => setShowCalendar((prev) => !prev)}>
          <Icon name={showCalendar ? "chevron-left" : "chevron-right"} />
        </StyledDivider>
        <StyledColumn>
          <Button rightIcon={true} leftIcon={false} icon="plus" onClick={handleOpenCreateModal}>
            Новое событие
          </Button>
          <StyledTableContainer>
            <StyledTitle>
              {formattedDateString} {dayLabel && <StyledDayLabel>{dayLabel}</StyledDayLabel>}
            </StyledTitle>
            {data?.getReminders.data?.map((r) => (
              <ReminderRow
                key={r.id}
                remindDate={r.remindDate}
                remindNotificationDate={r.remindNotificationDate}
                title={r.title}
                status={r.status}
                comment={r.comment}
                lead={r.lead}
                onClick={() => handleOpenReminder(r)}
              />
            ))}
          </StyledTableContainer>
        </StyledColumn>
      </StyledContainer>
      {modalContext?.modalCreateReminder && (
        <ModalCreateReminder
          leadId={leadId}
          userId={userId}
          date={selectedDate}
          transparentOverlay={isModal}
          onSuccess={refetch}
        />
      )}
      {modalContext?.modalEditReminder && selectedReminder && (
        <ModalEditReminder
          reminder={selectedReminder}
          onSuccess={refetch}
          transparentOverlay={isModal}
        />
      )}
      {modalContext?.modalReminderInfo && selectedReminder && (
        <ModalReminderInfo
          title={selectedReminder.title}
          remindDate={selectedReminder.remindDate}
          remindNotificationDate={selectedReminder.remindNotificationDate}
          comment={selectedReminder.comment}
          lead={selectedReminder.lead}
          user={hasPermissionForAllReminders ? selectedReminder.user : undefined}
          onDelete={() => setModalContext((prev) => ({ ...prev, modalDeleteReminder: true }))}
          onEdit={handleEditReminder}
          transparentOverlay={isModal}
        />
      )}
      {modalContext?.modalDeleteReminder && selectedReminder && (
        <ModalDeleteReminder
          reminderId={selectedReminder.id}
          onSuccess={() => {
            refetch();
            setModalContext((prev) => ({ ...prev, modalReminderInfo: false }));
          }}
        />
      )}
    </>
  );
};
